import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our Products</h1>
    <p>With effect from Thursday 14th October 2021 we will be making the following changes to our mortgage product range:</p>
    <p><strong>Summary of changes</strong></p>
    <p><strong>Residential Existing Customer Switching Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>Residential Existing Customer Borrowing More Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>Residential Remortgage Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 80% and 85% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 90% LTV has reduced</li>
      <li>3 Year Fixed Standard at 85% LTV has reduced</li>
      <li>5 Year Fixed Fee Saver at 85% and 90% LTV have reduced </li>
      <li>5 Year Fixed Standard at 85% LTV has reduced</li>
      <li>5 Year Fixed Premier Exclusive at 85% has reduced</li>
      <li>2 Year Fixed Standard at 60% LTV has increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has increased</li>
    </ul>
    <p><strong>Residential First Time Buyer Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 80%, 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 80%, 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Rate Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Rate Premier Exclusive at 60%, 70% and 75% LTV have increased</li>
    </ul>
    <p><strong>Residential Home Mover Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 80%, 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 80%, 85%, 90% and 95% (MGS) LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Rate Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60% LTV has increased</li>
      <li>5 Year Fixed Rate Premier Exclusive at 60%, 70% and 75% LTV have increased</li>
    </ul>
    <p>There are no changes to our fixed rate end dates or to any of the pricing of our Buy to Let range.</p>
    <p>An updated mortgage rate sheet will be published on Thursday 14<sup>th</sup> October 2021 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
  </NewsArticle>
)

export default Article
